/* HOME
================================================================================================ */
body.home {
    &.scrolled {
        .the-content {
        }
    }
    .the-content {
        position: relative;
        z-index: 2;
        padding: 0;
        .main {
            .pad {
                padding: 695px 50px 50px;
            }
        }
        .wrap {
            z-index: 2;
        }
        @include media($brTablet) {
            .main {
                .pad {
                    padding: 0 0 30px;
                    section {

                    }
                }
            }
        }
    }


    section {
        &.hero-content-block {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 1;
            overflow: hidden;
            .slideshow {
                @include clearfix;
                .slide-image {
                    height: 695px;
                    background: no-repeat center;
                    -webkit-background-size: cover;
                    background-size: cover;

                    .wrap {
                    }
                }

                .shade {
                    @include linear-gradient(rgba(#000, 0), rgba(#000, .3));
                }

                .animated-background-image {
                    background-position: 50% 0;
                }
                .slide-content {
                    position: absolute;
                    z-index: 3;
                    top: 100px;
                    padding-left: 390px;

                    .title {
                        margin: 0 0 20px 0;
                        padding: 0;
                        font-size: em(50);
                        font-family: $base-font-family;
                        color: #ffffff;
                    }

                    .desc {
                        margin: 0;
                        font-family: $base-font-family;
                        font-size: em(22);
                        font-weight: 400;
                        color: #ffffff;
                    }

                    .more {
                        .btn {
                            min-width: 280px;
                            color: #fff;
                            &:hover {
                                background-color: nth($color, 4);
                            }
                        }
                    }
                }
            }

            .cut {
                position: absolute;
                z-index: 5;
                bottom: 0;
                left: 0;
                right: 0;
                height: 95px;
                svg {
                    display: block;
                    width: 100%;
                    height: 100%;
                    .poly {
                        fill: $bgColor;
                    }
                }
            }


            @include media($brLargeDesktop) {
                .slideshow {
                    .slide-content {
                        padding-left: 330px;
                        .title {
                            font-size: em(45px);
                        }
                    }
                }
            }

            @include media($brDesktop) {
                .slideshow {

                    .slide-content {
                        padding-left: 300px;
                        .title {
                            font-size: em(40px);
                        }
                    }
                    .owl-controls {
                        bottom: 40px;
                    }
                }


            }

            @include media($brTablet) {
                position: relative;
                .slideshow {

                    .slide-image {
                        @include clearfix;
                        height: 380px;

                        .wrap {
                            border-top: 5px solid nth($color, 1);
                        }
                    }

                    .slide-content {
                        @include transform(none);
                        top: 20px;
                        padding: 0;

                        .title {
                        }
                    }

                    .owl-controls {
                        display: none;
                    }
                }

                .cut {
                    height: 22px;
                }

            }

            @include media($brMobile) {
                .slideshow {
                    .slide-image {
                    }

                    .slide-content {

                        .slide-title {
                        }
                        .slide-desc {
                            font-size: 12px;

                        }
                    }
                }
            }

        }

        &.category-slider,
        &.popular,
        &.news {
            header {
                @include clearfix;
                padding: 1em 0;
                h4 {
                    margin: 0;
                    padding: 0;
                    font-size: em(20);
                    font-weight: 400;
                    font-family: $base-font-family;
                }
            }
        }

        &.category-slider,
        &.popular {
            padding: 30px 0;
            border-bottom: 5px solid nth($color, 10);

            .slider,
            .list {
                .item {
                    position: relative;
                    z-index: 1;
                    margin-right: 10px;
                    background: no-repeat center;
                    -webkit-background-size: cover;
                    background-size: cover;

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        &:hover {
                            text-decoration: none;
                            .name {
                                color: nth($color, 1);
                                text-decoration: none;
                            }
                        }
                    }

                    figure {
                        @include rel-height(100%);
                        margin: 0 0 10px;
                        background: no-repeat center;
                        -webkit-background-size: cover;
                        background-size: cover;
                    }

                    .name {
                        margin: 0;
                        padding: 10px 10px 0 0;
                        font-family: $base-font-family;
                        font-size: 17px;
                        text-transform: uppercase;
                        background: $bgColor;
                        color: nth($color, 7);
                    }
                }
            }

            .owl-controls {
                position: relative;
                z-index: 1;
                padding-top: 30px;

                .owl-pagination {
                    text-align: center;
                    .owl-page {
                        span {
                            width: 10px;
                            height: 10px;
                            background: nth($color, 9);
                            border: 0;
                        }
                        &.active {
                            span {
                                background: nth($color, 1);
                            }
                        }
                    }
                }
                .owl-buttons {

                    .owl-prev,
                    .owl-next {
                        position: absolute;
                        top: 30px;
                        i {
                            color: nth($color, 9);
                        }
                        &:hover {
                            i {
                                color: nth($color, 1);
                            }
                        }
                    }

                    .owl-prev {
                        left: 20px;
                    }
                    .owl-next {
                        right: 20px;
                    }

                }
            }

            @include media($brMobile) {
                padding-bottom: 60px;

                .slider,
                .list {
                    margin-right: 0;
                }
                .owl-controls {
                    @include transform(none);
                    position: relative;
                    padding-top: 0;

                    .owl-pagination {
                        display: none;
                    }
                    .owl-buttons {
                        font-size: 24px;
                        .owl-next,
                        .owl-prev {
                            top: 0;
                        }
                        .owl-prev {
                            left: 0;
                        }
                        .owl-next {
                            right: 0;
                        }
                    }
                }
            }
        }

        &.category-slider {

        }

        &.popular {
            header {
                h4 {
                    float: left;
                }
                .view-all {
                    float: right;
                    i {
                        font-size: 12px;
                        color: nth($color, 1);
                    }
                    a {
                        color: #878787;
                        font-size: em(15);
                    }
                }
            }

            .list {
                @include clearfix;

                span.onsale {
                    top: 0;
                    left: auto;
                    right: 0;
                    z-index: 9;
                    margin: -.5em -.5em 0 0;
                }

            }

        }

        &.news {
            .posts {
                article {
                    @include clearfix;
                    margin-bottom: 20px;

                    .thumbnail {
                        float: left;
                        + .excerpt {
                            margin-left: 180px;
                        }
                    }

                    a {
                        color: nth($color, 6);
                        &:hover {
                            color: nth($color, 2);
                        }
                    }
                }
            }

            @include media($brMobile) {
                .posts {
                    article {
                        .thumbnail {
                            display: none;

                            + .excerpt {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }


        &.about {
            .post-content {
                line-height: 1.8;
                font-size: em(20);
                strong {
                    font-size: em(26);
                }
            }

            @include media($brTablet) {
                padding: 20px 0;
                .post-content {
                }
            }
        }
    }
}