/* NEAT SETTINGS
================================================================================================ */
$max-width: 1280px;

$brMobile: new-breakpoint(max-width 480px 4);
$brTablet: new-breakpoint(max-width 780px 6);
$brDesktop: new-breakpoint(max-width 1080px 12);
$brLargeDesktop: new-breakpoint(max-width 1380px 12);

$bgColor: #f4f4f4;

$color: (
        #d5b068, // 1
        #c4a260, // 2
        #66a8fc, // 3
        #5fd7ff, // 4
        #c37e46, // 5
        #202020, // 6
        #333333, // 7
        #878787, // 8
        #cccccc, // 9
        #eaeaea // 10
);

/* BITTERS SETTINGS
================================================================================================ */
$base-font-family: "Droid Sans", sans-serif;
$heading-font-family: "Noto Serif", serif;
$base-font-color: nth($color, 7);

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$base-border-radius: 0;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;

$fa-font-path: "../css/fonts";
$ionicons-font-path: "../css/fonts";

$buttons-list: 'button',
'input[type="button"]',
'input[type="reset"]',
'input[type="submit"]',
'a.button',
'button.button',
'button.button.alt',
'button.button.alt.disabled',
'a.button',
'a.button.alt',
'a.button.disabled',
'a.button.alt.disabled';

$all-buttons: assign-inputs($buttons-list);
$all-buttons-active: assign-inputs($buttons-list, active);
$all-buttons-focus: assign-inputs($buttons-list, focus);
$all-buttons-hover: assign-inputs($buttons-list, hover);