@import 'https://fonts.googleapis.com/css?family=Droid+Sans:400,700|Noto+Serif:400i,700i';
@import "vendor/normalize";
@import "vendor/bourbon/bourbon";
@import "vendor/neat/neat";
@import "variables";
@import "vendor/base/base";
@import "vendor/font-awesome/scss/font-awesome";
@import "vendor/ionicon/ionicons";
@import "mixins";
@import "classes";
@import "flashes";
@import "forms";
@import "head";
@import "foot";
@import "sidebar";
@import "general";
@import "home";
@import "woocommerce";

* {
    margin: 0;
    padding: 0;
    outline: none;
}

body, html {
    margin: 0;
    padding: 0;
}

body {
    font-size: 16px;
    line-height: 1.5;
    color: nth($color, 7);
    background: $bgColor;

    &.scrolled {
        padding-top: 191px;
    }

    @include media($brMobile) {
        font-size: 14px;
        &.scrolled {
            padding-top: 187px;
        }
    }
}

// HEADINGS
h1 {
    font-size: 2.5em;
    line-height: 1.1;
}

h2 {
    font-size: 2em;
    line-height: 1.25;
}

h3 {
    font-size: 1.5em;
    line-height: 1.25;
}

h4 {
    font-size: 1em;
    line-height: 1.5;
}

h5 {
    font-size: .88em;
}

h6 {
    font-size: .66em;
}

address {
    font-style: normal;
}

// LINK
a {
    color: nth($color, 1);
    text-decoration: none;
    &:hover {
        text-decoration: underline;
        &.unstyled {
            text-decoration: none;
        }
        button {
            text-decoration: none;
        }
    }
}

/* LAYOUT
================================================================================================ */

.wrap {
    @include outer-container($max-width);
    position: relative;

    @include media($brDesktop) {
    }

    @include media($brTablet) {
        width: auto;
        margin-left: 20px;
        margin-right: 20px;
    }
}

.the-content {
    @include layout(340px, (
            $brLargeDesktop: 280px,
            $brDesktop: 250px
    ));

    .wrap {
        padding: 0;
    }

    .main {
        > .pad {
            padding: 316px 30px 50px 40px;
        }
    }

    &.contact {
        .col {
            @include span-columns(6);
            @include omega(2n);
        }

        .phone,
        address {
            margin-bottom: 1em;
            p {
                margin-bottom: 0;
            }
        }

        div.gform_wrapper {
            padding: 30px 40px;
            background: #ffffff;

            li.gfield {
                padding-right: 0;
                &#field_1_4,
                &#field_1_1 {
                    float: left;
                    width: 47.5%;
                }
                &#field_1_4 {
                    margin-right: 5%;
                }

                &#field_1_1 {
                    clear: none;
                }

                &.gfield_error {
                    input[type=text],
                    input[type=email],
                    textarea {
                        border: 0 !important;
                        border-bottom: 5px solid #cc2226 !important;
                    }
                }

                input[type=text],
                input[type=email],
                textarea {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    border: 0;
                    border-bottom: 5px solid nth($color, 10);
                    border-radius: 0;
                    color: nth($color, 1);
                    box-shadow: none;
                    text-indent: 0;

                    &:focus {
                        border-color: nth($color, 1);
                    }
                }

                label {
                    font-size: 11px;
                }

            }

            .gform_footer {
                text-align: right;
                input {
                    margin-right: 0;
                }
            }

        }

        @include media($brTablet) {
            .col {
                float: none;
                margin: 0;
                width: auto;
                &.c1 {
                    margin-bottom: 2em;
                }
            }

        }

        @include media($brMobile) {
            div.gform_wrapper {
                padding: 1em;
                li.gfield {
                    &#field_1_4,
                    &#field_1_1 {
                        width: auto;
                        float: none;
                        margin-right: 0;
                    }
                }
            }
        }
    }

    @include media($brTablet) {

        .main {
            > .pad {
                padding: 260px 0 20px;
            }
        }

        .wrap {
        }
    }

    @include media($brMobile) {

        .main {
            > .pad {
                padding-top: 220px;
            }
        }
    }

}

/* CONTENT
================================================================================================ */

.post {
    @include clearfix;
    margin-bottom: 2em;
}

.post-header {
    margin-bottom: 1em;
    .post-title {
        margin: 0;
        padding: 0;
    }
    .post-meta {
        padding: 5px 0;
        font-size: 75%;
    }
}

.post-thumbnail {
    float: left;
    margin-right: 2em;
    margin-bottom: 1em;
    @include media($brMobile) {
        float: none;
        margin-right: 0;
    }
}

.post-excerpt {
    overflow: hidden;
    p {
        margin-top: 0;
    }
}

.section-header {
    padding: 0 0 2em;
    .page-title {
        margin: 0;
        padding: 0;
        font-size: em(45px);
    }

    @include media($brTablet) {
        .page-title {
        }
    }
}

.section-footer {
}

.blog,
.archive,
.search {
    article {
        margin-bottom: 2em;
    }
    .post-header {
    }
    .post-meta {
        clear: left;
    }
}

.single {
    .post-meta {
        padding-top: 1em;
    }
}

.single-team {
    .the-content {
        .wp-post-image {
            float: left;
            display: block;
            max-width: 100%;
            width: 39%;
            margin-right: -39%;
            padding: 10px;
            height: auto;
            background: #fff;
            box-shadow: 0 3px 20px rgba(nth($color, 4), .25);
        }

        .post-content {
            clear: left;
        }

        &.extra {
            .post-header {
                margin-left: 44%;

                .icons {
                    position: relative;
                    z-index: 1;
                    padding-left: 40px;
                    margin-bottom: 2em;
                    border-left: 1px solid nth($color, 1);
                }

                .position {
                    text-transform: uppercase;
                }

                p {
                    font-size: 20px;
                }

                a {
                    color: nth($color, 5);
                }

                .contact {
                    p {
                        line-height: 56px;
                    }
                }

                .quote {
                    margin-left: -3%;
                    p {
                        font-size: 16px;
                    }
                }
            }
        }
        & + .the-content {
            padding-top: 0;
        }
    }
}

/*! PLEASE DON'T EDIT THIS FILE. EDIT STYLE-ADDITIONAL.CSS INSTEAD */