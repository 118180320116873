#hero-image {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 296px;
    background: no-repeat center;
    -webkit-background-size: cover;
    background-size: cover;

    .wrap {
        height: 100%;
    }


    header {
        @include transform(translateY(-50%));
        position: absolute;
        z-index: 3;
        left: 390px;
        right: 0;
        top: 50%;

        .title {
            margin: 0 0 .2em;
            padding: 0;
            font-size: em(50);
            font-family: $base-font-family;
        }
        .subtitle {
            margin: 0 0 1em;
            padding: 0;
            font-size: 1.38em;
            font-family: $base-font-family;
        }

        .title,
        .subtitle {
            color: #ffffff;
        }

        .btn.big {
            height: 50px;
            color: #ffffff;
        }
    }

    .shade {
        @include linear-gradient(rgba(#000, .25), rgba(#000, .5));
    }

    .slant {
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        bottom: 0;
        height: 95px;
        svg {
            display: block;
            width: 100%;
            height: 100%;
            .poly {
                fill: $bgColor;
            }
        }
    }

    @include media($brLargeDesktop) {
        header {
            left: 330px;

        }
    }

    @include media($brDesktop) {
        header {
            left: 300px;
        }
    }
    @include media($brTablet) {
        height: 240px;
        font-size: .66em;
        header {
            left: 0;
        }

        .slant {
            height: 50px;
        }
    }

    @include media($brMobile) {
        height: 200px;
        font-size: .5em;
    }
}

#mini-cart {
    @include transition(width .3s, top .5s);
    position: fixed;
    z-index: 99;
    width: 39px;
    right: 0;
    top: 240px;

    &.open {
        width: 280px;
        border-bottom: 4px solid nth($color, 1);
        .pad {
        }
        .toggle.icon {
            display: none;
        }
    }

    h3 {
        margin: 0 0 .5em;
        padding: 0;
        line-height: 1;
        color: nth($color, 8);
    }

    ul {
        li {
            @include clearfix;
            color: nth($color, 9);
            margin-bottom: 1em;

            a {
                font-size: 14px;
                &:hover {
                    text-decoration: none;
                    color: #ffffff;
                }
            }
            .quantity,
            .variation {
                font-size: 12px;
                color: nth($color, 8);
            }
            .variation {
                padding: 0;
                border: 0;
                dd {
                    margin: 0;
                }
            }

        }
    }

    .buttons {
        margin: 0;
        text-align: center;
        .button {
            @include btnStyle;
            color: nth($color, 1);
            line-height: 32px;
        }
    }

    .toggle {
        &.icon {
            @include transition(background-color .4s);
            display: block;
            background: nth($color, 6);
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;

            &:hover {
                background-color: nth($color, 7);
            }
            img {
                display: block;
                padding: 5px;
            }
        }

        &.link {
            display: block;
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            &:hover {
                text-decoration: none;
                color: #ffffff;
            }
        }
    }

    .pad {
        display: none;
        padding: 20px;
        background: nth($color, 6);
        overflow: hidden;
    }

    @include media($brMobile) {
        &.open {
            width: 100%;
        }
    }
}

body.scrolled {
    #mini-cart {
        top: 150px;
    }

    @include media($brMobile) {
        #mini-cart {
            top: 100px;
        }
    }
}

#breadcrumbs {
    margin-bottom: 20px;
    padding: 10px 0;
    font-size: 12px;
    font-family: $heading-font-family;
    text-transform: uppercase;

    .post {
        &:after {
            display: none;
        }
    }

    span[typeof="ListItem"] {
        display: inline-block;
        vertical-align: middle;
        color: nth($color, 7);
    }

    @include media($brTablet) {
        font-size: 10px;
    }
}

.post-content {

    h1, h2, h3, h4, h5, h6 {
    }


    // LISTS
    ol,
    ul {
        margin: 1em 0;
        li {
            margin: 0.25em 0;
        }
        &.unstyled {
            padding-left: 0;
            margin: 0;
            list-style-type: none;
            li {
                margin: 0;
            }
        }
    }
    ol {
        @extend %default-ol;
    }
    ul {
        li {
            @include bullet-list(8px, nth($color, 2), 8px);
            padding-left: 1em;
        }
    }

    // PARAGRAPH
    p {
        margin: 0 0 1em;
    }

    // IMAGE
    img {
        display: block;
        max-width: 100%;
        height: auto;

        &[class*="wp-image"] {
            padding: 10px;
            background: #ffffff;
        }
    }

    // TABLE
    table {
        width: 100%;
        margin: 1em 0;
    }

    // OTHER STUFF
    blockquote {
        color: lighten($base-font-color, 15);
        margin: $base-spacing 0;
        padding-left: $base-spacing / 2;
    }
    small {
        font-size: 0.8em;
    }

    @include media($brTablet) {
        iframe {
            display: block;
            width: 100%;
        }
    }

    /* WORDPRESS CORE
================================================================================================ */
    .alignnone {
        float: none;
    }

    .alignright {
        display: inline;
        float: right;
        margin-left: 2em;
    }

    .alignleft {
        display: inline;
        float: left;
        margin-right: 2em;
    }

    .aligncenter {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .txt-alignleft {
        text-align: left;
    }

    .txt-aligncenter {
        text-align: center;
    }

    .txt-alignright {
        text-align: right;
    }

    .wp-caption {
        width: auto !important;
        margin-bottom: 20px;
        border-bottom: 3px solid #eaeaea;

        img {
            display: block;
            border: 0 none;
            height: auto;
            margin: 0;
            padding: 0;
            max-width: 100%;
            width: auto;
        }
        p.wp-caption-text {
            margin: 0;
            padding: 20px 0;
            font-size: em(28px);
            font-weight: 700;
        }
    }


}

/* GALLERY
================================================================================================ */
.post-content {
    ul.gallery {
        @extend .unstyled;
        @include clearfix;
        margin: 2em 0;
        li {
            @include span-columns(1 of 4);
            @include omega(5n);
            margin-bottom: 1em;
            a {
                img {
                    width: 100%;
                    height: auto;
                    padding: 4px;
                    border: 1px solid #e0e0e0;
                }

                &:hover {
                    img {
                        padding: 2px;
                        border: 3px solid #d0d0d0;
                    }
                }
            }
        }

        @include media($brTablet) {
            li {
                @include span-columns(1 of 3);
                @include omega(3n);
            }
        }

        @include media($brMobile) {
            li {
                @include span-columns(1 of 2);
                @include omega(2n);
            }
        }

    }
}

/* TESTIMONIALS
================================================================================================ */
.testimonials {
    .item {
        margin-bottom: 2em;
        padding-bottom: 3em;
        background: url(../images/testimonial-deco.png) no-repeat right bottom;

        &.featured {
            width: auto;
            padding: 0 0 1em 0;

            .testimonial {
                font-size: em(28px);
                font-weight: 700;
            }

            .client {
                display: inline-block;
                padding-right: 20px;
                background: #fff;
            }
        }
        .testimonial {
            padding: 0;
            margin: 0 0 1em;
        }
        .client {
            color: #000;
        }
    }

    .cols {
        @include clearfix;
        $gutter: .0941;
        .col {
            float: left;
            width: percentage((1 - $gutter) / 2);
            &:nth-child(2n) {
                margin-left: percentage($gutter);
            }
        }
    }

    @include media($brMobile) {
        .cols {
            .col {
                float: none;
                width: auto;
                &:nth-child(2n) {
                    margin-left: 0;
                }
            }
        }
    }

}


#team {
    ul {
        li {
            @include clearfix;
            display: block;
            margin-bottom: 2em;
        }
    }

    .thumbnail {
        float: left;
        margin-right: 2em;
        img {
        }
    }

    .details {
        .name {
            margin: 0;
            font-size: 35px;
        }
        .meta {
            p {
                margin: 0;
            }
        }

        .more {
            padding-top: 1em;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    @include media($brDesktop) {
        .thumbnail {
            width: 170px;
        }
    }
}