/* GENERAL SETTINGS
================================================================================================ */


/* GRAVITY FORM SETTINGS
================================================================================================ */
body {
    div.gform_wrapper {
        max-width: none;
        margin: 0;
        overflow: hidden;

        textarea {
            padding-top: .5em;
            padding-bottom: .5em;
        }

        ul.gform_fields {
            li.gfield {
                background: none;
                padding-left: 0;
                padding-right: 0;

                &.gfield_error {
                    width: 100%;
                    border: none;
                    margin-top: 0 !important;
                    margin-bottom: 0 !important;
                    div.ginput_container {
                        width: 100%;
                        max-width: none;
                        margin-left: 0;
                        margin-top: 0;
                        input[type=text],
                        input[type=tel],
                        input[type=email],
                        input[type=number],
                        input[type=password],
                        select,
                        textarea {
                            border: 2px solid #d9403a;
                        }
                    }
                }
                div.ginput_container {
                    input[type=text],
                    input[type=tel],
                    input[type=email],
                    input[type=number],
                    input[type=password],
                    select,
                    textarea {
                        padding-left: 1em;
                        padding-right: 1em;
                        text-indent: 0;
                    }

                    &.ginput_complex {
                        input,
                        select {
                            & + label {
                                display: none;
                            }
                        }
                        span[class^=ginput_] {
                            display: inline-block;
                            margin-bottom: 10px;
                            &.ginput_full {
                                width: 100%;
                                input[type="text"] {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .top_label {
            .gfield_label {
                display: block;
                margin: 0 0;
                font-style: normal;
            }
        }

        label.gfield_label {
            & + div.ginput_container {
                margin: 0 0 10px;
                input,
                select,
                textarea {
                    width: 100%;
                }
            }
        }

        .gform_body {
            width: auto;
        }

        .gform_footer {
            margin: 0;
            padding: 0;
        }

        .gform_validation_container {
            display: none;
        }

        div.validation_error {
            width: 100%;
            margin-bottom: 10px;
            padding: 10px;
            font-size: 14px;
            background: #f6c9cc;
            border: none;
        }

        .validation_message {
            display: none;
        }

        .gform_confirmation_message {
            padding: 10px;
            background: #d1f3c0;
        }

        .recaptchatable {
            input[type="text"] {
                height: auto;
            }
        }

    }
}

body {
    select {
        @extend input[type="text"];
        width: 100%;
        margin-bottom: 0.75em;
    }

    #{$all-text-inputs},
    select {
        height: 45px;
    }

    #{$all-text-inputs},
    select[multiple=multiple],
    .select2-container .select2-choice,
    textarea {
        background-color: #fff;
        border: 0;
        border-bottom: 3px solid nth($color, 10);

        &:hover {
            border-color: darken($base-border-color, 10%);
        }

        &:focus {
            border-color: nth($color, 1);
            outline: none;
        }
    }

    .select2-container .select2-choice {
        height: 45px;
        line-height: 45px;
    }

    fieldset {
        #{$all-text-inputs},
        select[multiple=multiple],
        .select2-container .select2-choice,
        textarea {
            background: #ffffff;
        }
    }

    input[type=number] {
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
}