.woo-content {


    &.category {


    }

    &.single-product {
        .summary {
            h1 {
                margin-bottom: .5em;
            }

            div[itemprop="description"] {
                padding-bottom: 1em;
                border-bottom: 1px solid nth($color, 10);
            }

            table.variations {
                padding: 0;
                table-layout: auto;

                td {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
                .label {
                    label {
                        font-weight: 400;
                        font-size: em(18px);
                        line-height: 67px;
                    }
                }
                .value {
                    select {
                        height: 67px;
                        margin: 0;
                        padding: 0 1em;
                        font-weight: 700;
                    }
                }
                .reset_variations {
                    position: absolute;
                    right: 0;
                }
            }
            .variations_form {
                .single_variation_wrap {
                    button[type=submit] {
                        display: none;
                    }
                }
            }

            .woocommerce-variation-price,
            [itemprop="offers"] {
                @include clearfix;
                margin-bottom: 25px;
                padding: 25px 0;
                line-height: 50px;
                border-top: 1px solid nth($color, 10);
                border-bottom: 3px solid nth($color, 10);

                .price {
                    ins, del {
                        &:before {
                            display: inline;
                            margin-right: 5px;
                            font-size: rem(16px);
                            font-weight: 400;
                            color: nth($color, 7);
                        }
                    }
                    ins {
                        font-size: 2em;
                        text-decoration: none;
                        &:before {
                            content: "Now";
                        }
                    }

                    del {
                        margin-right: 10px;
                        font-style: normal;
                        text-decoration: none;
                        opacity: 1;

                        &:before {
                            content: "Was";
                        }
                    }
                    span {
                        &.label,
                        &.amount {
                            display: inline-block;
                            vertical-align: baseline;
                        }

                        &.label {
                            margin-right: 1em;
                            font-size: 18px;
                            color: nth($color, 7);
                        }
                        &.amount {
                            font-weight: 700;
                            color: nth($color, 1);

                        }
                    }
                }
            }

            .notice {
                padding: 1em 0;
                font-weight: 700;
            }

            .variations_button,
            .cart {
                @include clearfix;

                .quantity {
                    position: relative;
                    margin-right: 0;
                    float: right;

                    &:before {
                        display: inline-block;
                        content: 'Quantity:';
                        margin-right: 1em;
                        font-size: 18px;
                    }

                    input {
                        display: inline-block;
                        height: 67px;
                        max-width: 65px;
                        margin: 0;
                        font-size: em(20px);
                        font-weight: 700;
                        text-align: center;
                    }
                }
                button {
                    height: 67px;
                    font-size: em(20px);
                    font-weight: 700;
                    border-width: 4px;
                }
            }

            .variations_button {
            }
        }

        .documents {
            clear: both;
            padding: 30px 0;
            border-top: 1px solid nth($color, 10);
            ul {
                @include column-count(2);

                li {
                    display: block;
                    padding: 5px 0;
                    img, span {
                        display: inline-block;
                        vertical-align: middle;
                    }
                    img {
                        margin-right: 20px;
                    }
                }
            }
        }

        .diagram {
            margin-bottom: 2em;

            h4 {
                font-size: em(22px);
            }
            img {
                display: block;
            }
        }

        div.wc-tabs-wrapper {
            ul.tabs {
                margin: 0;
                padding: 0;
                &:before {
                    border-bottom: 4px solid nth($color, 10);
                }
                li {
                    margin: 0;
                    padding: 0;
                    border: 0;
                    border-bottom: 4px solid transparent;
                    background-color: transparent;
                    &:before,
                    &:after {
                        display: none;
                    }
                    &.active {
                        background-color: transparent;
                        border-bottom-color: nth($color, 4);
                        a {
                            color: nth($color, 7);
                        }
                    }
                    a {
                        display: block;
                        padding: 25px 30px;
                        font-size: em(14px);
                        text-transform: uppercase;
                        color: #bdbdbd;
                    }
                }
            }
            .wc-tab {
                padding: 0 30px;
                h2 {
                    padding-bottom: 0;
                }
            }
        }

        .related.products {
            @include clearfix;
            margin-left: -50px;
            margin-right: -50px;
            padding: 40px 50px;
            background: #f3f3f3;

            h2 {
                padding-top: 0;
            }
        }

        @include media($brLargeDesktop) {
            .summary {
                .notice {
                    clear: right;
                }
                .variations_button,
                .cart {
                    .quantity {
                        input {
                            width: 2.5em;
                        }
                    }
                }
            }
        }

        @include media($brDesktop) {
            div.wc-tabs-wrapper {
                ul.tabs {
                    li {
                        a {
                            padding: 15px 20px;
                        }
                    }
                }

                .wc-tab {
                    padding: 0 20px;

                }
            }

            .related.products {
                margin-left: -20px;
                margin-right: -20px;
                padding: 20px;
            }
        }

        @include media($brTablet) {
            .summary {
                .variations_button,
                .cart {
                    .quantity {
                        input {
                            width: 3.5em;
                        }
                    }
                }
            }
            .onsale {
                position: static;
                display: block;
                height: auto;
                min-height: 0;
                margin-bottom: 5px;
                padding: 10px 0;
                border-radius: 0;
                line-height: 1;
            }
            div.wc-tabs-wrapper {
                ul.tabs {
                    margin-left: -20px;
                    margin-right: -20px;
                    font-size: .88em;

                    li {

                        a {

                        }
                    }
                }

                .wc-tab {
                    padding: 0;
                }
            }

            .documents {
                ul {
                    @include column-count(1);
                }
            }

            .related.products {
                margin-left: -20px;
                margin-right: -20px;
            }
        }

    }


    @include media($brDesktop) {

    }

    @include media($brTablet) {
        padding-top: 0;

    }

    @include media($brMobile) {

    }
}

.woocommerce {
    $spacing: .0106;

    a.button,
    input[type=submit],
    .button[type=submit] {
        @include btnStyle(".solid");
        line-height: 36px;
    }

    ul.products {
        @include clearfix;
        li.product {
            width: percentage((1 - (3 * $spacing)) / 4);
            margin: 0 percentage($spacing) 20px 0;
            position: relative;
            z-index: 1;

            &:nth-child(4n) {
                margin-right: 0;
            }

            a {
                display: block;

                img {
                    position: relative;
                    z-index: 1;
                    display: block;
                    width: 100%;
                    height: auto;
                    margin: 0;
                }

                &:hover {
                    h3 {
                        color: nth($color, 1);
                    }
                }
            }

            h3 {
                margin: 0;
                padding: 10px 10px 0 0;
                font-family: $base-font-family;
                font-size: 17px;
                text-transform: uppercase;
                color: nth($color, 7);
                mark {
                    display: none;
                }
            }

            &.type-product {
                padding-bottom: 66px;
                &.product-type-variable {
                    .more {
                        left: 10px;
                        width: auto;
                        text-align: center;
                    }
                }

                figure {
                    position: relative;
                    margin: 0 0 10px;
                }

                .info {
                    margin-bottom: 1em;
                    color: nth($color, 7);
                    .desc {
                        max-height: 4.5em;
                        overflow: hidden;
                    }
                    p {
                        margin: 0;
                    }
                }

                a.btn {
                    position: absolute;
                    z-index: 1;
                    display: block;
                    bottom: 0;
                    width: 100%;
                    height: 56px;
                    margin: 0 0 0 0;
                    padding: 0 1em;
                    font-weight: 700;
                    line-height: 56px;
                    background: #ffffff;
                    box-shadow: 0 1px 1px #ccc;
                    text-align: center;
                    text-transform: uppercase;
                    color: nth($color, 7);
                    &:hover {
                        color: nth($color, 1);
                    }
                }

                .onsale {
                    z-index: 9;
                }

                .more {
                    right: 10px;
                    bottom: 9px;
                    width: 40px;
                    line-height: 1.1;
                }
            }

            &.product-category {
                a {
                    width: 100%;
                    height: 100%;
                    color: nth($color, 7);
                    &:hover {
                        color: nth($color, 2);
                    }
                    h2 {
                        font-family: $base-font-family;
                        text-transform: uppercase;
                        mark {
                            font-size: .8em;
                            background: none;
                        }
                    }
                }

            }
        }
    }

    .woocommerce-cart {
        table.shop_table {
            .button.alt {
                @include btnStyle(".solid");

            }
        }
    }

    table.account-orders-table {

    }

    .woocommerce-error {
        li {
            &:before {
                display: none;
            }
        }
    }



    nav.woocommerce-pagination {
        margin-bottom: 20px;
        ul {
            li {
                a, span {
                    font-size: em(26px);
                }
            }
        }
    }

    @include media($brDesktop) {
        ul.products {
            li.product {
                @include omega-reset(4n);
                @include omega(3n);
                @include span-columns(4);
            }
        }
    }

    @include media($brTablet) {

        ul.products {
            li.product {
                @include omega-reset(3n);
                @include omega(2n);
                @include span-columns(6 of 12);

            }
        }
    }

    @include media($brMobile) {
        ul.products {
            li.product {
                @include omega-reset(2n);
                @include fill-parent;
                margin: 0 0 20px 0 !important;
            }
        }
    }
}

body.archive {
    .category-header {
        margin-bottom: 2em;
        padding-bottom: 1em;
        border-bottom: 5px solid nth($color, 10);

        .term-description {
            font-size: em(20);
        }
    }


    @include media($brDesktop) {
        article {
            .woo-content {
            }
        }
    }

    @include media($brTablet) {
        .category-header {
        }

        form.filters {
            padding: 20px 20px 10px;
        }
    }

    @include media($brMobile) {

        form.filters {
            label {
                width: auto !important;
                margin: 0 0 10px 0 !important;
                float: none !important;

            }
        }
    }
}

body.single-product {

    article {
        .woo-content {
        }

        .actions {
            clear: both;
            padding: 30px 0;
            border-top: 5px solid nth($color, 10);
            border-bottom: 5px solid nth($color, 10);
            text-align: center;
            .msgErrorOptions {
                padding: 1em;
                color: #b30000;
            }
            .addquotelistlink {
                display: inline;
                div, span {
                    display: inline !important;
                }
                + .clear {
                    display: none !important;
                }
            }

            a,
            a.button {
                position: relative;
                z-index: 1;
                display: inline-block;
                vertical-align: middle;
                height: auto;
                margin: 0 5px;
                padding: 30px;
                font-size: em(18);
                font-weight: 700;
                line-height: 1.1em;
                border: none;
                background: #ffffff;
                box-shadow: 0 1px 1px #ccc;
                text-transform: none;
                color: nth($color, 7);
                text-align: center;

                &:hover {
                    color: nth($color, 2);
                    text-decoration: none;
                    &.disabled {
                        background: none;
                    }
                    mark {
                        color: nth($color, 7);
                    }
                }

                img {
                    @include align-center-v(2);

                    + span {
                        padding-left: 40px;
                    }
                }

                mark {
                    background: none;
                    color: nth($color, 2);
                }
            }
        }

        .additional-info {
            clear: both;

            .section-heading {
                font-family: $base-font-family;
                color: nth($color, 2);
                text-transform: uppercase;
            }
        }
    }

    @include media($brTablet) {
        article {
            .actions {
                &.has-both {
                    a {
                        @include fill-parent;
                        &:first-child {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}

.woocommerce-pagination {
    @include media($brTablet) {
        font-size: 10px;
    }
}

.pp_woocommerce {
    .ppt {
        display: none !important;
    }
}

body.logged-in {
    #gform_wrapper_3 {
        li#field_3_6 {
            display: none;
        }
    }
}