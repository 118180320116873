.the-content {
    .sidebar {
        background: $bgColor;
        margin-bottom: 50px;


        section {
            @include clearfix;

            &.sidebar-box {
                padding: 40px;
                border-left: 4px solid nth($color, 10);
                border-bottom: 4px solid nth($color, 10);
                .content {
                    margin-left: 25px;
                }
                .title {
                    @include bullet-list(8px, nth($color, 1), 12px, "\f24f");
                    margin: 0 0 1em;
                    padding: 0 0 0 25px;
                    font-size: em(22px);
                    font-style: italic;
                    &:before {
                        font-style: normal;
                    }
                }
            }

        }

        .widget {
            margin-bottom: 20px;
            p {
                margin: 0;
            }
            .widgettitle {
                margin: 0;
                font-weight: 300;
                color: nth($color, 1);
            }
        }

        .box {

        }
        #side-nav {
            margin-bottom: 2em;
            .root-page {
                padding: 0;
                font-size: 1.5em;
                font-weight: 600;
                color: nth($color, 1);
            }
            ul {
                margin: 0;
                li {
                    position: relative;
                    z-index: 1;
                    line-height: 1.2;

                    &:before {
                        position: absolute;
                        display: block;
                        left: 0;
                        top: 12px;
                        width: 9px;
                        height: 14px;
                        content: '';
                    }

                    &.current-menu-item {
                        > a {
                            color: nth($color, 3);
                        }
                    }

                    a {
                        display: block;
                        padding: 10px 20px 10px 30px;

                        &:hover {
                            color: nth($color, 2);
                            text-decoration: none;
                        }
                    }

                    .sub-menu {
                        font-size: .66em;

                    }
                }
            }
        }

        #services-menu {
            li {
                @include bullet-list(12px, nth($color, 1), 7px, "\f3d3");

            }
            a {
                font-size: 15px;
                color: nth($color, 8);
            }
        }
        #store-hours {
            border-bottom: 0;

            .item {
                margin-bottom: .5em;
                font-size: 15px;
                font-weight: 700;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .days {
                color: nth($color, 8);
            }
        }

        #map {
            position: relative;
            z-index: 1;
            iframe {
                position: relative;
                z-index: 1;
                display: block;
                width: 100%;
            }

            .map-overlay {
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                &.disabled {
                    pointer-events: none;
                }
            }
        }




        @include media($brDesktop) {

            section {
                &.sidebar-box {
                    padding: 20px;
                }
            }
        }

        @include media($brTablet) {
            padding: 0;

            section {
                &.sidebar-box {
                    border-left: 0;
                    &#services-menu {
                        border-top: 4px solid nth($color, 10);
                    }
                }
            }


            #side-nav {
                margin-bottom: 0;
                padding: 20px;
                background: #fff;

                ul {
                    li {
                        &.current-menu-item {
                        }
                    }
                }
            }

            #contact-box {

            }
        }
    }
}

#product-categories {
    position: relative;
    z-index: 3;
    padding: 40px 0;
    font-size: em(18px);
    background: #ffffff;
    box-shadow: 0 0 50px rgba(#000, .2);

    .title {
        padding: 0 40px;
        font-family: $base-font-family;
        color: nth($color, 1);
        text-transform: uppercase;
    }

    > ul {
        > li {

            position: relative;
            padding: 0 40px;

            &:hover {
                > .children {
                    display: block;
                }

                > a {
                    text-decoration: none;
                    span {
                        &.name {
                            color: nth($color, 1);
                        }
                    }

                    i {
                        color: nth($color, 7);
                    }
                }
            }

            &.current {
                a {
                    color: nth($color, 4);
                    font-weight: 700;
                }
            }
            &:last-child {
                a {
                }
            }

            > .children {
                @include clearfix;
                display: none;
                position: absolute;
                top: 0;
                left: 100%;
                padding: 20px 40px;
                background: nth($color, 7);

                > li {
                    @include span-columns(4);
                    @include omega(3n);
                    margin-bottom: 1em;

                    > a {
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                    a {
                        color: #ffffff;
                        &:hover {
                            color: nth($color, 1);
                            text-decoration: none;
                        }

                        i {
                            display: none;
                        }
                    }

                    > .children {

                    }

                    .children {
                        a {
                            font-weight: 400;
                            font-size: 14px;
                            i {
                                display: none;
                            }
                        }
                    }
                }

                li {
                    &.current {
                        > a {
                            color: nth($color, 1);
                        }
                    }
                }
            }

            > a {
                position: relative;
                display: block;
                padding: .3em 0;
                color: nth($color, 7);

                > span {
                    display: block;
                    &.name {
                        padding-right: 25px;
                        font-weight: 700;
                    }
                }

                > i {
                    position: absolute;
                    top: 7px;
                    right: 0;
                    font-size: em(14px);
                    color: nth($color, 1);
                }

            }
        }
    }

    @include media($brDesktop) {
        padding: 20px 0;
        .title {
            padding: 0 20px;
        }

        > ul {
            > li {
                padding: 0 20px;
                > .children {
                    padding: 20px;
                }
            }
        }
    }

    @include media($brTablet) {
        margin-left: -20px;
        margin-right: -20px;
        box-shadow: none;
        > ul {
            > li {
                &:hover {
                    > .children {
                        display: none;
                    }
                }
            }
        }
    }
}