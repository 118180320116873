@import "dialogs";


.shade {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.pagination {
    ul {
        font-size: em(15px);
        text-align: center;
        li {
            display: inline-block;
            &.active {
                a {
                    font-weight: 700;
                    color: #ffffff;
                    background: nth($color, 4);
                }
            }
            a {
                display: block;
                padding: 0 5px;
                background: nth($color, 10);
                &:hover {
                    color: #ffffff;
                    background: nth($color, 1);
                    text-decoration: none;
                }
            }

            i {
                font-size: em(12em);
            }
        }
    }
}


ul.accordion {
    li {
        display: block;

        &.active {
            .header {
                i {
                    @include transform(translateY(-50%) rotate(-180deg));
                }
            }

            .content {
            }
        }

        .header {
            position: relative;
            z-index: 1;
            padding: .5em 1em;
            background: $bgColor;
            border-radius: $base-border-radius;
            cursor: pointer;

            .title {
                margin: 0;
                padding: 0;
                color: nth($color, 1);
            }

            i {
                @include transform(translateY(-50%));
                @include transition(transform .4s);
                position: absolute;
                top: 50%;
                right: 1em;
                font-size: 1.2em;
                color: nth($color, 8);
            }
        }

        .content {
            display: none;
            padding: 0 1em;
        }
    }
}

.post-content {
    ul.accordion {
        &.sales {
            li {
                margin-bottom: 10px;
                .header {
                    background: #fff;
                    h3 {
                        margin: 0;
                    }
                }

                .content {
                    @include clearfix;
                    padding: 20px 0;
                }

            }
        }
        li {
            padding: 0;
            &:before {
                display: none;
                content: '';
            }
        }
    }

    ul.tabs {
        @include clearfix;
        margin: 0;
        border-bottom: 3px solid #eaeaea;
        text-transform: uppercase;
        li {
            float: left;
            display: block;
            padding: 0;
            margin: 0;

            &:before {
                display: none;
            }

            a {
                display: block;
                padding: 0 1em;
                font-size: .88em;
                line-height: 35px;
                color: nth($color, 7);
                &:hover {
                    text-decoration: none;
                    background: nth($color, 4);
                }
                &.active {
                    font-weight: 700;
                    background-color: #eaeaea;
                }
            }
        }
    }
}


.tab-content {
    display: none;
    padding-top: 1em;
    &.active {
        display: block;
    }
}

.ui-tooltip {
    position: absolute;
    z-index: 9999;
    .ui-tooltip-content {
        max-width: 200px;
        padding: 10px;
        font-size: .66em;
        background: $bgColor;
        border-radius: $base-border-radius;
        box-shadow: 0 0 5px rgba(nth($color, 1), .25);
    }
}

.social-networks {
    white-space: nowrap;

    a {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-left: 5px;
        text-align: center;
        &:first-child {
            margin-left: 0;
        }

        i {
            @include transition(color .5s);
            line-height: 32px;
            font-size: 28px;
            color: nth($color, 8);
        }



        &:hover {
            i {
                color: nth($color, 1);
            }
        }

    }
}

#dialog-overlay {
    display: none;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, .9);
    &.active {
        display: block;
    }
}



.owl-controls {
    .owl-pagination {
        .owl-page {
            display: inline-block;
            margin: 0 5px;
            &.active {
                span {
                    background: #ffffff;
                }
            }
            span {
                display: block;
                width: 16px;
                height: 16px;
                border: 2px solid #fff;
                border-radius: 50%;
            }
        }
    }
}



.fl {
    float: left;
}
.fr {
    float: right;
}
.fn {
    float: none;
}
.clr {
    clear: both;
}
.tal {
    text-align: left;
}
.tar {
    text-align: right;
}
.tac {
    text-align: center;
}
.hide {
    display: none;
}

/* COLUMNS
================================================================================================ */
	@include media( $brTablet ) {
		@for $i from 1 through 12 {
			.cols-#{$i} {
				@include span-columns( $i of 12 );
			}
		}
		.omega {
			@include omega;
		}
	}

