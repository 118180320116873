@mixin flash( $color ) {
  background: $color;
  color: darken( $color, 60 );

  a {
    color: darken( $color, 70 );

    &:hover {
      color: darken( $color, 90 );
    }
  }
}

%flash-base {
  font-weight: bold;
  margin-bottom: $base-spacing / 2;
  padding: $base-spacing / 2;
}

%flash-alert {
  @extend %flash-base;
  @include flash( #FFF6BF );
}

%flash-error {
  @extend %flash-base;
  @include flash( #FBE3E4 );
}

%flash-notice {
  @extend %flash-base;
  @include flash( lighten( #477DCA, 40 ) );
}

%flash-success {
  @extend %flash-base;
  @include flash( #E6EFC2 );
}