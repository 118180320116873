@mixin layout($sbWidthDefault, $breakpoints) {


    .flex {
        @include clearfix;

        .main {
            float: left;
            width: 100%;
            padding-left: $sbWidthDefault;
        }

        .sidebar {
            float: left;
            width: $sbWidthDefault;
            margin-left: -100%;
        }
    }

    @each $br, $width in $breakpoints {
        @include media($br) {
            .flex {
                .sidebar {
                    width: $width;
                }

                .main {
                    padding-left: $width;
                }
            }

        }
    }

    @include media($brLargeDesktop) {
        .sidebar {
        }
    }

    @include media($brDesktop) {

        .flex {
            .main {
            }
            .sidebar {
            }
        }
    }

    @include media($brTablet) {

        .flex {
            display: block;
            .main {
                float: none;
                width: auto;
                padding-left: 0;
            }
            .sidebar {
                float: none;
                width: auto;
                margin: 0 -20px;
            }
        }
    }
}

@mixin align-center-v($zindex: 1) {
    @include transform(translateY(-50%));
    position: absolute;
    z-index: $zindex;
    top: 50%;
}

@mixin align-center-h($zindex: 1) {
    @include transform(translateX(-50%));
    position: absolute;
    z-index: $zindex;
    left: 50%;
}

@mixin align-center-vh($zindex: 1) {
    @include transform(translate(-50%, -50%));
    position: absolute;
    z-index: $zindex;
    top: 50%;
    left: 50%;
}

@mixin rel-height($height) {
    position: relative;
    float: left;
    width: 100%;
    &:before {
        content: '';
        display: block;
        margin-top: $height;
    }
}

@mixin omega-reset($nth) {
    &:nth-child(#{$nth}) {
        margin-right: flex-gutter();
    }
    &:nth-child(#{$nth}+1) {
        clear: none
    }
}

@mixin bullet-list($bullet-size: rem(12px), $bullet-color: nth($color, 3), $top: rem(12px), $icon: "\f21b") {
    position: relative;
    padding-left: 1.2em;

    &:before {
        position: absolute;
        top: $top;
        left: 0;
        display: block;
        content: $icon;
        font-family: "Ionicons", sans-serif;
        font-size: $bullet-size;
        color: $bullet-color;
        text-shadow: none;
        line-height: 1;
    }
}

@mixin icon-heading($icon-width: 38px, $position: relative) {
    position: $position;
    padding-left: $icon-width + 12px;

    .icon {
        @include transform(translateY(-50%));
        position: absolute;
        top: 50%;
        left: 0;
        max-width: $icon-width;
        vertical-align: middle;
    }
}

@mixin icon-button($text-dir, $size: 56px, $color: nth($color, 7)) {
    @include clearfix;
    display: inline-block;
    border: 2px solid $color;
    color: $color;

    &:hover {
        text-decoration: none;
        background: $color;
        i, span {
            color: #ffffff;
        }
        i {
            border-color: #666;
        }
    }

    i, span {
        float: left;
        display: block;
        vertical-align: middle;
        line-height: $size;
    }
    i {
        width: $size;
        text-align: center;
        border-#{$text-dir}: 2px solid $color;
    }
    span {
        padding-left: 1em;
        padding-right: 1em;
        font-weight: 700;
        text-transform: uppercase;
    }
}

@mixin heading-underline($width: 50px, $color: nth($color, 4), $align: left) {
    position: relative;

    &:after {
        position: absolute;
        display: block;
        width: $width;
        height: 3px;
        margin-top: .88em;
        background: $color;
        content: '';

        @if $align == center {
            @include transform(translateX(-50%));
            left: 50%;
        }
    }
}



/* BUTTONS
================================================================================================ */
@mixin btnStyle($extend: null) {
    @include transition(background-color .3s);

    display: inline-block;
    height: 35px;
    padding: 0 20px;
    border: 2px solid nth($color, 1);
    font-size: 12px;
    font-weight: 600;
    font-family: $base-font-family;
    text-transform: uppercase;
    color: nth($color, 7);
    text-decoration: none;
    cursor: pointer;
    background: transparent;
    text-align: center;
    border-radius: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover,
    &:active {
        color: #ffffff;
        background: nth($color, 1);
        text-decoration: none;
    }
    &.disabled {
        background-color: transparent;
    }
    &.alt {
        color: #fff;
        background-color: transparent;
        &:hover {
            background-color: nth($color, 1);
            color: #fff;
        }
    }
    &.strong {
        border-color: nth($color, 3);
        &:hover {
            border-color: nth($color, 1);
        }
    }
    &.solid {
        color: #fff;
        background-color: nth($color, 1);
        border: none;
        &:hover {
            background-color: nth($color, 2);
        }
        &.alt {
            color: #fff;
            background-color: nth($color, 1);
            &:hover {
                background-color: nth($color, 2);
            }
        }
        &.strong {
            background-color: nth($color, 3);
            &:hover {
                background-color: nth($color, 1);
            }
        }
    }
    &.big {
        height: 50px;
    }
    &.huge {
        height: 70px;
        font-size: 16px;
    }

    &.big,
    &.huge {
        font-size: 16px;
    }

    &.shadow {
        box-shadow: 0 3px 5px rgba(#000, .25);
    }

    @if $extend != null {
        @extend #{$extend};
    }

    @include media($brMobile) {
        &.huge {
            height: 60px;
        }

        &.big {
            height: 45px;
        }

        &.big,
        &.huge {
            font-size: 14px;
        }

    }
}

#{$all-buttons} {
    @include btnStyle();
}

body {
    .gform_wrapper {
        input[type=submit] {
            @extend .solid;


        }
    }
}
