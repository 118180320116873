/* HEADER
================================================================================================ */
.the-header {
    position: relative;
    z-index: 10;
    background-color: nth($color, 6);

    ul, li {
        margin: 0;
        padding: 0;
    }

    .flex-box {
        @include display(flex);
        @include justify-content(space-between);
        padding: 0 30px;
    }

    #utility-bar {
        padding: 5px 0;
        font-size: 12px;
        text-align: right;
        background: #000;
        border-bottom: 1px solid nth($color, 7);
        ul {
            li {
                display: inline-block;
                vertical-align: middle;
                &:last-child {
                    &:after {
                        display: none;
                    }
                }
                &:after {
                    content: ' | ';
                    display: inline-block;
                    color: rgba(#fff, .5);
                }
            }
        }

        a {
            &:hover {
                text-decoration: none;
                color: #ffffff;
            }
        }
    }

    #logo {
        @include transition(padding .5s);

        display: block;
        img {
            @include transition(height .5s);
            display: block;
            height: 123px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    #logo,
    .slogan-search,
    .contact {
        padding: 30px 0;
    }

    .menu-toggle {
        display: none;

    }

    .nav-header {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 1;
    }


    .search-toggle {
        display: inline-block;
        margin-left: 20px;
        i {
            color: nth($color, 1);
        }
        &:hover {
            i {
                color: nth($color, 3);
            }
        }
    }
    ul.main-menu {
        @include clearfix;
        padding-left: 362px;
        text-transform: uppercase;
        font-size: em(18px);
        font-weight: 700;

        &:before {
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            right: 0;
            display: block;
            content: "";
            border-top: 4px solid nth($color, 1);
        }

        > li {
            position: relative;
            display: inline-block;
            border-top: 4px solid transparent;

            &:hover {
                > ul {
                    display: block;
                }
            }

            &:hover {
                &.menu-item-has-children {
                    > a:after {
                        color: #ffffff;
                    }
                }
            }
            &:first-child {
                margin-left: 0;
            }

            &.menu-item-has-children {
                > a {
                    &:after {
                        display: block;
                        position: absolute;
                        left: 0;
                        right: 0;
                        font-family: "Ionicons", sans-serif;
                        font-size: 12px;
                        line-height: 1;
                        content: '\f3d0';
                        text-align: center;
                        color: nth($color, 9);
                    }
                }
            }
            &.current_page_item,
            &.current_page_parent,
            &.current_page_ancestor {
                border-top-color: #fff;

                > a {
                }
            }

            > a {
                display: block;
                color: #fff;
                text-align: center;
                padding: 2em 1.5em 0;
                &:hover {
                    color: nth($color, 1);
                }
            }


            a {
                &:hover {
                    text-decoration: none;
                }
            }
            ul {

                position: absolute;
                left: 0;
                z-index: 101;
                text-align: left;
                line-height: 1;
                font-size: 14px;
                background: #fff;
                border-bottom: 3px solid #fff;
                text-transform: none;
                white-space: nowrap;
                box-shadow: 0 2px 4px rgba(#000, 0.25);

                li {
                    display: block;
                    a {
                        display: block;
                        padding: 10px 22px;
                        color: nth($color, 1);
                        text-decoration: none;
                        &:hover {
                            background: rgba(nth($color, 1), .25);
                        }
                    }
                }
            }
        }

        ul {
            display: none;
        }

        a {
            color: nth($color, 4);
        }
    }

    .slogan-search,
    .contact {
        font-family: $heading-font-family;
        font-style: italic;
    }

    .slogan-search {
        text-align: center;

        .slogan {
            margin-bottom: .5em;
            color: #ffffff;
            font-size: em(24);
        }
        .search-form {
            position: relative;
            label {
                margin: 0;
            }
            input {
                width: 500px;
                height: 80px;
                margin: 0;
                padding: 0 30px;
                font-family: $heading-font-family;
                color: nth($color, 1);
                border: 0;
                background: #161616;
                &:focus {
                    background: nth($color, 7);
                }
            }
            button {
                @include align-center-v(2);
                right: 0;
                border: 0;
                &:hover {
                    background: none;
                    i {
                        color: #ffffff;
                    }
                }
                i {
                    font-size: 24px;
                    color: nth($color, 1);
                }
            }
        }

        .phone {
            display: none;
        }
    }

    .contact {
        text-align: right;
        color: #ffffff;
        .phone {
            font-size: em(30);
            i {
                margin-right: 10px;
                color: nth($color, 1);
            }
            a {
                color: #ffffff;
            }
        }

        address {
            font-size: em(20);
            font-style: italic;

            .line-3 {
                font-size: .88em;
                opacity: .5;
            }
        }
    }

    .mini-cart {
        display: none;
        position: absolute;
        z-index: 99;
        right: 0;
        min-width: 250px;
        padding: 10px;
        background: nth($color, 7);
        color: nth($color, 9);
        box-shadow: 0 3px 15px rgba(nth($color, 4), .5);

        ul.cart_list {
            text-align: left;
            li {
                @include clearfix;
                position: relative;
                display: block;
                padding: 10px 0;
                border-bottom: 1px solid rgba(#fff, .2);

                a {
                    &.remove {
                        position: absolute;
                        left: 0;
                        top: 6px;

                        + a {
                            display: block;
                            padding-left: 25px;
                            font-size: em(14px);
                            img {
                                float: right;
                            }
                        }
                    }
                }



                dl.variation {
                    padding-left: 25px;
                    border-left: 0;
                    font-size: 13px;
                    dd {
                        margin: 0;
                        padding: 0;
                        p {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
                .quantity {
                    clear: both;
                    display: block;
                    padding: .5em 0 0;
                    text-align: right;

                    .amount {
                        font-weight: 700;
                        color: nth($color, 4);
                        span {
                            color: nth($color, 4);
                        }
                    }
                }



            }
        }
        p {
            margin: 0;
            &.total {
                padding: 1em 0;
                font-size: em(18px);
                line-height: 1;
                .amount {
                    font-weight: 700;
                    font-size: 1em;
                    color: nth($color, 4);
                    span {
                        color: nth($color, 4);
                    }
                }
            }
            &.buttons {
                text-align: center;
                a.button {
                    @include btnStyle();
                    line-height: 32px;
                    color: #ffffff;
                }
            }
        }

    }

    #growl {
        @include transition(opacity .3s);
        position: absolute;
        z-index: 100;
        right: 40px;
        top: 110%;
        display: none;

        .content {
            padding: 1em;
            background: #fff;
            border: 2px solid nth($color, 4);
            box-shadow: 0 0 15px rgba(#000, .5);
            .product {
                font-weight: 700;
            }
        }
    }

    @include media($brLargeDesktop) {

        #utility-bar {
            .wrap {
                padding: 0 30px;
            }
        }

        ul.main-menu {
            padding-left: 302px;

            > li {
                > a {
                    font-size: .88em;
                }
            }
        }
    }

    @include media($brDesktop) {

        .wrap {
        }

        #logo {
            img {
                height: 75px;
            }
        }
        #growl {
            right: 20px;
        }


        .nav-header {
        }

        .slogan-search {
            .slogan {
                font-size: 18px;
            }
            .search-form {
                input {
                    width: 350px;
                    height: 45px;
                }
            }
        }

        .contact {
            font-size: .66em;
        }

        ul.main-menu {
            padding-left: 285px;
            > li {
                &.menu-item-has-children {
                    > a:after {
                    }
                }
                > a {
                    padding: 20px 1em 0;
                    font-size: .88em;
                }

                ul {
                }
            }
        }
    }

    @include media($brTablet) {
        padding: 40px 0 20px;
        > .wrap {
        }

        .flex-box {
            @include display(block);
            padding: 0;
        }

        #utility-bar {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            .wrap {
                padding: 0;
            }
        }

        #logo {
            padding: 0;
            img {
                height: 67px;
            }
        }

        #growl {
            left: 20px;
        }

        .menu-toggle {
            position: absolute;
            top: 0;
            right: 20px;
            display: block;
            i {
                font-size: 42px;
                color: #fff;
            }
        }

        .nav-header,
        .slogan-search .slogan,
        .contact {
            display: none;
        }

        .slogan-search {
            padding-top: 20px;
            padding-bottom: 0;
            .search-form {
                input {
                    width: 100%;
                    height: 45px;
                }
            }

            .phone {
                display: block;
                margin: 0;
                padding-top: 10px;
                font-size: 20px;
                line-height: 1;
                i {
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 24px;
                    margin-right: 20px;
                    color: rgba(#fff, .5);
                }
            }
        }
    }

    @include media($brMobile) {
        .partner-logo {
            img {
                width: 100px;
            }
        }
    }
}

#mobile-nav {
    $width: 100%;
    $padding: 5%;
    $subpadding: 10px;
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10000;
    width: $width;
    height: 100%;
    background: rgba(nth($color, 7), .95);
    overflow-y: auto;

    &.off {
        @include transform(translate($width, 0));
        visibility: hidden;
    }
    &.on {
        @include transform(translate(0, 0));
        @include transition(transform .3s);
        visibility: visible;
        + #the-container {
            //@include transform(translate(-$width, 0));
            //@include transition(transform .3s);
        }
        .menu {
            text-align: right;

            li {
                position: relative;
                padding: 0 $padding;

                &:last-child {
                    margin-bottom: 0;
                }
                &.menu-item-has-children {
                }
                &.current-menu-item {
                    background-color: rgba(nth($color, 1), .2);
                    > a {
                        color: #fff;
                        border-bottom: 0;
                    }
                }

                a {
                    display: block;
                    color: #fff;
                    border-bottom: 1px solid rgba(#fff, .15);
                }
            }

            > li {

                &.heading {
                    > a {
                        font-size: 14px;
                        color: rgba(#fff, .5);
                    }
                }
                > a {
                    padding: 10px 0;
                    text-decoration: none;
                    text-transform: uppercase;
                }

                > .sub-menu {
                    font-size: .88em;

                    li {
                        padding: 0;
                    }
                    a {
                        padding: 10px 0 10px $subpadding;
                        text-decoration: none;
                    }

                }
            }
        }
    }


    .menu-trigger {
        margin-bottom: 20px;
        padding: 0 $padding;
        display: block;
        text-align: right;
        text-decoration: none;
        i {
            color: #fff;
            font-size: 32px;
        }
    }

    @include media($brTablet) {
        display: block;
    }
}

body.scrolled {
    &.home {
        .the-header {
        }
    }
    .the-header {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;

        .wrap {
        }

        #utility-bar {
            display: none;
        }

        .slogan-search,
        .contact {
            display: none;
        }

        .nav-header {
            @include align-center-v(2);
            left: 0;
            right: 0;
        }

        #logo {
            position: relative;
            z-index: 10;
            padding: 10px 0;
            img {
                height: 60px;
            }
        }

        ul.main-menu {
            &:before {
                display: none;
            }
            > li {
                border-top: 0;
                &:hover {
                    > a {
                    }
                }
                &.menu-item-has-children {
                    > a {
                        &:after {
                            top: 1.8em;
                        }
                    }
                }

                > a {
                    padding-top: 0;
                }

                ul {
                }
            }
        }

    }
    &.admin-bar {
        .the-header {
            top: 32px;
        }
    }

    @include media($brLargeDesktop) {
        .the-header {

            .nav-header {
            }
            ul.main-menu {
                > li {
                    &:hover {
                        > a {
                        }
                    }
                    &.menu-item-has-children {
                        > a {
                            &:after {
                                top: 1.4em;
                            }
                        }
                    }

                    > a {
                    }

                    ul {
                    }
                }
            }
        }
    }

    @include media($brDesktop) {
        .the-header {
            #logo {
                img {
                    height: 50px;
                }
            }

            ul.main-menu {
                > li {
                    &:hover {
                        > a {
                        }
                    }
                    &.menu-item-has-children {
                        > a {
                            &:after {
                                top: 1.2em;
                            }
                        }
                    }

                    > a {
                    }

                    ul {
                    }
                }
            }
        }
    }

    @include media($brTablet) {
        .the-header {
            padding: 10px 0;
            #logo {
            }

            .slogan-search {
                display: none;
            }
        }
    }
}

body.admin-bar {

    #mobile-nav {
        top: 32px;
    }
    @include media($brTablet) {
        &.scrolled {
            .the-header,
            #mobile-nav {
                top: 45px;
            }
        }
        #mobile-nav {
            top: 45px;
        }
    }

    @include media($brMobile) {
        &.scrolled {
            .the-header,
            #mobile-nav {
                top: 0;
            }
        }
    }
}