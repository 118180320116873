/* FOOTER
================================================================================================ */
.the-footer {
    position: relative;
    z-index: 1;
    color: #999999;
    .wrap {
    }


    a {
        color: #999;
    }

    .social-networks {
    }

    .row {
        @include clearfix;
        &:last-child {
            border-bottom: 0;
        }

        &.r1 {
            padding: 40px 0;
            background: nth($color, 1);
            text-align: center;
            img {
                margin-bottom: 1em;
            }
            .text {
                color: #ffffff;
                font-size: em(20);
                font-weight: 700;
            }
        }
        &.r2 {
            background: #1b1b1b;
            .wrap {
                padding: 40px 0;
                border-top: 4px solid #fff;
            }

            .flex-box {
                @include display(flex);
                @include justify-content(space-between);
            }

            .col {
                margin: auto;

                &.c1,
                &.c2 {
                    @include flex-grow(1);
                }

                &.c1 {
                    &.mobile {
                        display: none;
                    }
                    a {
                        position: relative;
                        display: inline-block;
                        padding: 30px 0;
                        font-weight: 700;
                        font-size: em(17);
                        color: nth($color, 1);
                        &:hover {
                            color: #ffffff;
                        }
                        &:before,
                        &:after {
                            @include transform(translateX(-50%));
                            position: absolute;
                            left: 50%;
                            z-index: 1;
                            width: 75%;
                            content: "";
                        }
                        &:before {
                            top: 0;
                            border-top: 4px solid #121212;
                        }
                        &:after {
                            bottom: 0;
                            border-bottom: 4px solid #121212;
                        }
                    }
                }

                &.c2 {
                    @include flex-grow(2);
                }

                &.c3 {
                    text-align: right;
                    p {
                        font-size: 12px;
                        text-transform: uppercase;
                        white-space: nowrap;
                    }
                }
            }


        }

        &.r3 {
            padding: 30px 0;
            background: #121212;
            .col {
                &.c1 {
                    float: left;
                }
                &.c2 {
                    float: right;
                }
            }
        }
    }

    .contact {
        font-size: em(13);

        address, .phone {
            display: inline-block;
            vertical-align: middle;
            div {
                display: inline-block;
            }
        }

        address {
            margin-right: 30px;
        }
    }

    .copyright {
        font-size: em(13px);
        text-align: right;
        p {
            margin: 0;
            a {
                color: nth($color, 8);
            }
            .tds {
            }
        }
    }
    .nav-footer {
        @include display(flex);
        @include justify-content(space-between);

        ul {
            @include flex-grow(1);
            padding-left: 20px;
            border-left: 4px solid nth($color, 1);

            li {
                display: block;
                a {
                    font-size: em(14px);
                    font-weight: 700;
                    color: #999;
                    &:hover {
                        text-decoration: none;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    @include media($brLargeDesktop) {
        .row {
            &.r1,
            &.r2,
            &.r3 {
                .wrap {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }

            &.r2 {
                .col {

                }
            }
        }
    }

    @include media($brDesktop) {
        .row {
            &.r3 {
                .col {
                    &.c1,
                    &.c2 {
                        float: none;
                        text-align: center;
                    }
                }

                .contact {
                    margin-bottom: 20px;
                    address {
                        margin: 0;
                    }
                }
                .copyright {
                    float: none;
                    text-align: center;
                }
            }
        }
    }

    @include media($brTablet) {
        .row {

            &.r1 {
                padding: 20px 0;
            }

            &.r2 {

                .wrap {
                    padding: 20px 0;
                }

                .flex-box {
                    @include display(block);
                }

                .col {
                    padding: 0;

                    &.c1,
                    &.c3 {
                        text-align: center;
                    }

                    &.c1 {
                        display: none;
                        &.mobile {
                            display: block;
                            margin-bottom: 20px;
                        }
                    }
                }
            }

            &.r3 {

            }
        }



    }


    @include media($brMobile) {
        .nav-footer {
            display: block;
            margin-bottom: 20px;
            padding: 0;
            ul {

            }
        }
    }
}

.floating-ad {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid nth($color, 1);
    background: #b30000;

    .content {
        position: relative;
        padding: 20px 40px;
        min-height: 50px;
    }

    .text {
        padding-right: 160px;
        font-size: em(28);
        color: #ffffff;
    }

    .btn {
        position: absolute;
        right: 60px;
        top: 20px;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        &:hover {
            i {
                color: #ffffff;
            }
        }
        i {
            line-height: 50px;
            font-size: 24px;
        }
    }

    @include media($brTablet) {
        .content {
            padding: 10px 20px;
            min-height: 0;
        }

        .text {
            font-size: 14px;
        }

        .btn {
            top: 10px;
            height: 36px;
            font-size: 14px;
        }

        .close {
            top: 10px;
            i {
                line-height: 36px;
                font-size: 16px;
            }
        }
    }
}