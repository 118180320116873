.dialog {
    @include align-center-vh(1000);
    position: fixed;
    display: none;
    max-width: 700px;
    width: 80%;
    padding: 40px;
    background: #fff;

    &.active {
        display: block;
    }

    header {
        margin: 0 0 1em 0;
        position: relative;
        h3 {
            margin: 0;
            padding: 0;
            text-transform: uppercase;
        }

        a.close {
            @include align-center-v(2);
            right: 0;
            color: nth($color, 7);
            &:hover,
            &:active {
                i {
                    color: nth($color, 4);
                }
            }

            i {
                font-size: 28px;
            }
        }
    }

    &#search-dialog {
        .content {
            .input {
                margin-bottom: 2em;
                position: relative;
                z-index: 2;
                .submit {
                    @include align-center-v(3);
                    right: 1em;
                    i {
                        font-size: 28px;
                    }
                }

                input {
                    height: 80px;
                    font-weight: 400;
                    background: #f2f2f2;
                    border: 0;
                    border-bottom: 3px solid nth($color, 9);
                    box-shadow: none;
                    border-radius: 0;
                    &::-ms-clear {
                        display: none;
                    }
                    &:focus {
                        border-color: nth($color, 4);
                    }
                }
            }

            .results {
                .hint {
                    text-align: center;
                    font-weight: 700;
                }

                .products {
                    @include clearfix;
                    li {
                        @include span-columns(4);
                        @include omega(3n);
                        margin-bottom: 1em;
                        background: #eaeaea;
                        text-align: center;
                        border: 3px solid transparent;
                        &:hover,
                        &:active {
                            border-color: nth($color, 4);
                        }

                        img {
                            display: block;
                            height: auto;
                        }

                        .name {
                            margin: 0;
                            padding: 5px;
                            font-size: em(13px);
                        }
                    }
                }
            }
        }

        @include media($brMobile) {
            .content {
                .results {
                    .products {
                        li {
                            @include span-columns(1 of 2);
                            @include omega(2n);
                        }
                    }
                }
            }
        }
    }

    &#quick-view-dialog {
        .product-lightbox {
            @include clearfix;

            .image {
                width: 40%;
                figure {
                    margin: 0;
                    img {
                        display: block;
                        width: 100%;
                    }
                }
            }
            .details {
                width: 60%;
                padding-left: 40px;
                .name {
                    font-size: em(22px);
                    text-transform: uppercase;
                }

                .variations {
                    table-layout: auto;

                    td {
                        border-bottom: 0;
                    }
                    select {
                        margin: 0;
                    }

                    label {
                        font-weight: 400;
                    }

                    .reset_variations {
                        position: absolute;
                    }
                }
            }

            .image,
            .details {
                float: left;
            }

            .footer {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 40px;
                background: #f3f3f3;

                .quantity,
                .button {
                    display: inline-block;
                    vertical-align: middle;
                }

                .quantity {
                    input {
                        height: 50px;
                        margin: 0;
                        background: #ffffff;
                    }
                }

                .button.alt {
                    height: 50px;
                    font-size: em(16px);
                    border: 3px solid nth($color, 4);
                    background-color: transparent;
                    border-radius: 0;
                    color: nth($color, 7);
                    &:hover,
                    &:active {
                        background: nth($color, 4);
                        color: #ffffff;
                    }
                }

                .price {
                    float: left;
                    width: 40%;
                    margin: 0;
                    text-align: center;
                    line-height: 1;
                    font-size: em(50px);
                    font-weight: 700;
                    color: nth($color, 4);

                    del {
                        font-size: 25px;
                        color: nth($color, 9);
                    }

                    ins {
                        text-decoration: none;
                    }
                }

                .cart,
                .woocommerce-variation-add-to-cart {
                    float: right;
                    width: 60%;
                    text-align: right;
                }

            }
        }
        .content {
            padding-bottom: 130px;

            .results {



            }
        }

        @include media($brMobile) {
            .content {
                padding-bottom: 0;
            }

            .product-lightbox {
                .image,
                .details {
                    float: none;
                    width: auto;
                }

                .details {
                    padding: 1em 0 0;
                }

                .footer {
                    position: static;
                    background: none;

                    .cart,
                    .woocommerce-variation-add-to-cart,
                    .price {
                        float: none;
                        display: block;
                        width: auto;
                        text-align: center;
                    }

                    .price {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .content {
        .loader {
            &:before {
                display: none !important;
            }
            text-align: center;
        }
    }
    @include media($brTablet) {
        width: 95%;
        padding: 20px;
    }
}