fieldset {
    background-color: lighten($base-border-color, 10%);
    border: $base-border;
    margin: 0 0 $small-spacing;
    padding: $base-spacing;
}

input,
label,
select {
    display: block;
    font-family: $base-font-family;
    font-size: $base-font-size;
}

label {
    font-weight: 600;
    margin-bottom: $small-spacing / 2;

    &.required::after {
        content: "*";
    }

    abbr {
        display: none;
    }
}


#{$all-text-inputs},
select[multiple=multiple],
textarea {
    box-sizing: border-box;
    font-family: $base-font-family;
    font-size: $base-font-size;
    margin-bottom: $base-spacing / 2;
    padding: $base-spacing / 3;
    transition: border-color;
    width: 100%;
}

textarea {
    resize: vertical;
}

input[type="search"] {
    @include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
    display: inline;
    margin-right: $small-spacing / 2;
}

input[type="file"] {
    padding-bottom: $small-spacing;
    width: 100%;
}

select {
    margin-bottom: $base-spacing;
    max-width: 100%;
    width: auto;
}
